import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HeaderService } from './headers/header.service';
import { IAuth } from '../open-age/core/services/auth.interface';
import { RoleService } from '../open-age/core/services';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private header : HeaderService,
    private role: RoleService
  ) { }

  get(url: string): Observable<any> {
    const subject = new Subject<any>();
    this.http.get(url, { headers: this.header.getHeaders() }).subscribe((res: any) => {
      const isSuccess = res.isSuccess !== undefined ? res.isSuccess : (res as any).IsSuccess;
      if (!isSuccess) {
        const error = new Error(res.error || res.code || res.message || 'failed');
        return this.handleError(error, subject);
      }
      else {
        subject.next(res);
      }
    }, (e) => {
      return this.handleError(e, subject);
    });
    return subject.asObservable();
  }

  post(url: string, params: any): Observable<any> {
    const subject = new Subject<any>();
    this.http.post(url, params, { headers: this.header.getHeaders() }).subscribe((res: any) => {
      const isSuccess = res.isSuccess !== undefined ? res.isSuccess : (res as any).IsSuccess;
      if (!isSuccess) {
        const error = new Error(res.error || res.code || res.message || 'failed');
        return this.handleError(error, subject);
      }
      else {
        subject.next(res);
      }
    }, (e) => {
      return this.handleError(e, subject);
    });
    return subject.asObservable();
  }


  handleError(err: any,  subject: Subject<any>) {
    let isLogout = false

    if (err.error) {
      if (err.error.error == "INVALID_DEVICE" || err.error.error == "SESSION_EXPIRED" || err.error.message == "INVALID_DEVICE" || err.error.message == "SESSION_EXPIRED" || err.error.code == "INVALID_DEVICE" || err.error.code == "SESSION_EXPIRED") {
        isLogout = true
      }
    }

    let error = err.error && (err.error.error || err.error.message || err.error.code) ? (err.error.error || err.error.message || err.error.code) : err.message || err;

    if (typeof error == "object") {

      if (error.error) {
        error = error.error
      }

      if (error.message) {
        error = error.message
      }

      if (error.code) {
        error = error.code
      }
    }

    subject.error(error);
    if (isLogout) {
      this.role.logout()
    }
  }
}
