import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import { RouterModule } from '@angular/router';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { AvatarModule } from 'ngx-avatar';
import { DirectoryModule } from '../open-age/directory/directory.module';
import { AvatarComponent } from './components/avatar/avatar.component';
import { CountdownClockComponent } from './components/countdown-clock/countdown-clock.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { OnlynumberDirective } from './components/directive/numberOnly';
import { DisplayImageDialogComponent } from './components/display-image-dialog/display-image-dialog.component';
import { DocViewDialogComponent } from './components/doc-view-dialog/doc-view-dialog.component';
import { ErrorComponent } from './components/error/error.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { JsonEditorComponent } from './components/json-editor/json-editor.component';
import { MonthPickerComponent } from './components/month-picker/month-picker.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PreviousNextButtonsComponent } from './components/previous-next-buttons/previous-next-buttons.component';
import { ProcessingIndicatorComponent } from './components/processing-indicator/processing-indicator.component';
import { QueryBuilderComponent } from './components/query-builder/query-builder.component';
import { ShowCarouselComponent } from './components/show-carousel/show-carousel.component';
import { StatusSelectorComponent } from './components/status-selector/status-selector.component';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { NamePipe } from './pipes/name.pipe';
import { TimePipe } from './pipes/time.pipe';

import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ClockedSelectorComponent } from './components/clocked-selector/clocked-selector.component';
import { TimeZonePipe } from './pipes/timezone.pipe';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';

const angularModules = [
  CommonModule,
  FormsModule,
  RouterModule,
  ReactiveFormsModule,
  DirectoryModule,
];

const thirdPartyModules = [
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatCardModule,
  MatButtonModule,
  MatIconModule,
  MatCheckboxModule,
  MatInputModule,
  MatFormFieldModule,
  MatSelectModule,
  MatRadioModule,
  MatAutocompleteModule,

  MatDividerModule,
  MatSlideToggleModule,
  MatDatepickerModule,
  MatMenuModule,
  MatToolbarModule,
  MatDatepickerModule,
  MatNativeDateModule,
  AvatarModule,
  AngularMultiSelectModule,
  MatButtonToggleModule,
  MatListModule,
  MatDividerModule
];

const dialogs = [
  DisplayImageDialogComponent,
  DocViewDialogComponent,
  ConfirmDialogComponent,
  ClockedSelectorComponent
];

const sharedComponents = [
  CountdownClockComponent,
  JsonEditorComponent,
  UnderConstructionComponent,
  ErrorComponent,
  PreviousNextButtonsComponent,
  ShowCarouselComponent,
  AvatarComponent,
  QueryBuilderComponent,
  ProcessingIndicatorComponent,
  DatePickerComponent,
  MonthPickerComponent,
  StatusSelectorComponent,
  OnlynumberDirective,
  PaginatorComponent,
  FileUploaderComponent,
  ConfirmDialogComponent,
  ClockedSelectorComponent
];
const pipes = [
  NamePipe,
  TimePipe,
  TimeZonePipe,
  PhoneNumberPipe
];
@NgModule({
  imports: [
    ...angularModules,
    ...thirdPartyModules,
  ],

  exports: [
    ...angularModules,
    ...thirdPartyModules,
    ...sharedComponents,
    ...dialogs,
    ...pipes
  ],
  declarations: [
    ...sharedComponents,
    ...dialogs,
    ...pipes,
  ],
  providers: [
  ],
  entryComponents: [
    ...dialogs
  ],
})
export class SharedModule { }
