import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Role, User } from 'src/app/open-age/directory/models';
import { Link, Menu, Nav } from '~/app/open-age/core/structures';
import { RoleService } from '../../../open-age/core/services/role.service';
import { TenantService } from '../../../open-age/core/services/tenant.service';
import { Organization } from '../../../open-age/directory/models/organization.model';
import { Tenant } from '../../../open-age/directory/models/tenant.model';
import { NavService } from '../../services';
import { UxService } from '../../services/ux.service';
import { CodeDialogComponent } from '../code-dialog/code-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {

  errors: string[] = [];
  breadcrumb: Link[] = [];
  searchText = '';

  currentRole: Role;

  currentUser: User;

  currentTenant: Tenant;

  currentOrganization: Organization;

  searchClick = true;

  showSideNav = false;

  device: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public navService: NavService,
    public uxService: UxService,
    private auth: RoleService,
    private tenantService: TenantService,
    public snackBar: MatSnackBar
  ) {
    uxService.errors.subscribe((error) => {
      this.snackBar.open(error, null, { duration: 5000, verticalPosition: 'top', panelClass: 'error' });
      this.errors.push(error);
      // this.cleanErrors();
    });

    auth.roleChanges.subscribe((r) => this.currentRole = r);
    auth.userChanges.subscribe((r) => this.currentUser = r);
    auth.tenantChanges.subscribe((t) => this.currentTenant = t);
    auth.organizationChanges.subscribe((t) => this.currentOrganization = t);
    navService.breadcrumbChanges.subscribe((l) => this.breadcrumb = l || []);

    uxService.deviceChanges.subscribe((d) => this.device = d);

    uxService.sideNavShowChanges.subscribe((show) => {
      if (this.device !== 'mobile') {
        this.showSideNav = show;
      }
    });
  }

  ngOnInit() {
    this.currentTenant = this.auth.currentTenant();
    this.currentRole = this.auth.currentRole();
    this.currentUser = this.auth.currentUser();
    this.currentOrganization = this.auth.currentOrganization();
    this.device = this.uxService.getDevice();
    this.showSideNav = this.uxService.getShowSideNav();
    this.route.queryParams.subscribe((query) => {
      const searchText = query['search'] || '';
      if (this.searchText !== searchText) {
        this.searchText = searchText;
        setTimeout(() => this.search(searchText), 1);
      }
    });
  }

  logout = () => {
    // this.router.navigate(['/', 'home', 'login']);
    this.auth.logout();
    // location.reload();
  }

  search($event) {

    const text: string = typeof $event === 'string' ? $event : $event.target.value;

    const queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
    queryParams['search'] = text || undefined;
    this.router.navigate([], { queryParams });
    if (text) {
      this.uxService.setSearchText(text);
    } else {
      this.uxService.resetSearchText();
    }
  }

  resetSearch() {
    this.searchText = '';
    this.search('');
  }

  setTenant() {
    this.uxService.openDialog(CodeDialogComponent).afterClosed().subscribe((code: string) => {
      if (!code) {
        return;
      }

      this.tenantService.get(code).subscribe((i) => {
        this.auth.setTenant(i);
      });
    });
  }

  getUrl(role) {
    
    if (role.organization && role.organization.logo && role.organization.logo.url) {
      return role.organization.logo.url
      // "https://openage.github.io/themes/oppos/v1/icons/placeholder/hospital-placeholder.svg"
    } else if (role.tenant && role.tenant.logo && role.tenant.logo.url && role.type.name=="System Manager") {
      // console.log("coming here in tenant ",role.tenant.logo.url)
      return role.tenant.logo.url
      //  "https://openage.github.io/themes/oppos/v1/icons/placeholder/hospital-placeholder.svg"
    } else if (role.tenant && role.tenant.logo && role.tenant.logo.url && role.type.name=="Account Manager") {
      // console.log("coming here in tenant ",role.tenant.logo.url)
      return "https://openage.github.io/themes/oppos/v1/icons/placeholder/hospital-placeholder.svg"
      //role.tenant.logo.url
    } else if (this.currentTenant && this.currentTenant.logo && this.currentTenant.logo.url) {
      return this.currentTenant.logo.url
    } else {
      return null
    }
  }

  selectRole(role) {
    this.auth.setRoleWithoutRefresh(role)
    this.navService.goto("/home/dashboard/default")
    setTimeout(() => {
      location.reload()
    }, 100)

  }
}
