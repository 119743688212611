import { ErrorHandler, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Entity } from '../../core/models/entity.model';
import { RoleService } from '../../core/services';
import { IInputValidator } from '../../core/services/input-validator.interface';
import { Doc, Folder, Placeholder } from '../models';
import { DocsService } from '../services';
import { DomSanitizer } from '@angular/platform-browser';
import { SafeUrl } from '@angular/platform-browser';

export class FileUploaderBaseComponent implements OnInit {

  @Input()
  entity: Entity;

  @Input()
  doc = new Doc()

  @Input()
  types: string[];

  @Input()
  maxFiles: number;

  /*
      limits files to one folder only
  */
  @Input()
  folder: Folder;

  /*
      Set of files to show with + icon. These are just placeholder for files
  */
  @Input()
  placeholder: Placeholder; // true will shows set of files configured for that entity type

  @Input()
  multiple?: boolean; // default true

  @Input()
  isNew = false

  @Input()
  orderNo: number

  @Output()
  created: EventEmitter<Doc> = new EventEmitter();

  files: File[] = [];

  thumnailLimit = 4

  closeDialog: () => void;

  isProcessing: boolean;
  isProcessingUrl: boolean;
  isProcessingThumbnail: boolean;

  readyForDrop = false;
  fileDropped = false;
  url: string | SafeUrl | any;
  scaleFactor: number = 0.25;
  thumbnails = []
  selectedThumbnail

  constructor(
    private auth: RoleService,
    private fileService: DocsService,
    private errorHandler: ErrorHandler,
    private validator: IInputValidator,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    if (!this.placeholder) {
      this.placeholder = new Placeholder();
    }
    if (!this.doc.orderNo && this.doc.orderNo != 0) {
      this.doc.orderNo = this.orderNo
    }
  }

  emptyList() {
    this.files = []
    this.url = null
    this.thumbnails = []
  }

  upload() {
    delete this.doc.url;
    if (this.doc.id && !this.isNew) {
      
      if (!this.doc.name || !this.doc.description) {
        return this.errorHandler.handleError(`Title and Descrption is Required`);
      }
      this.fileService.update(this.doc.id, this.doc).subscribe(doc => {
        this.closeDialog()
      })
    } else {
      if (!this.doc.name || !this.doc.description) {
        return this.errorHandler.handleError(`Title and Descrption is Required`);
      }
    }
    if (!this.doc.id) {
      this.doc.overwrite = "false"
      this.doc.status = "draft"
    }
    if (this.files.length >= this.maxFiles) {
      return this.errorHandler.handleError(`Max Files Uploads-${this.maxFiles}`);
    }
    let count = this.files.length
    for (const file of this.files) {
      const fileSize: number = Number((file.size / (1024 * 1024)).toFixed(2));

      // if (fileSize > 10) {
      //   count--
      //   return this.errorHandler.handleError(`File size is greater than 10 mb`);
      // }

      if (this.types && this.types.length) {
        let match = false;
        if (this.types.find((i) => i === file.type)) {
          match = true;
        }
        if (!match) {
          count--
          return this.errorHandler.handleError(`Only ${this.types.join()} are supported`);
        }
      }
      this.isProcessing = true;
      if (this.doc.id) {
        this.fileService.createByEntity(this.entity, file, this.doc, this.folder).subscribe((i) => {
          count--
          this.isProcessing = false;
          this.created.emit(i);
          if (!count) {
            this.closeDialog()
          }
        }, (error) => {
          count--
          this.errorHandler.handleError(error.message || error);
          this.isProcessing = false;
          if (!count) {
            this.closeDialog()
          }
        });
      } else {
        this.fileService.searchByEntity(this.entity, this.folder, null, null, { isPublic: true }, this.doc.name.trim().toLowerCase().replace(' ', '-')).subscribe(page => {
          if (!page.items.length) {
            this.fileService.createByEntity(this.entity, file, this.doc, this.folder).subscribe((i) => {
              count--
              this.isProcessing = false;
              this.created.emit(i);
              if (!count) {
                this.closeDialog()
              }
            }, (error) => {
              count--
              this.errorHandler.handleError(error.message || error);
              this.isProcessing = false;
              if (!count) {
                this.closeDialog()
              }
            });
          } else {
            this.errorHandler.handleError("Already Exists");
            this.isProcessing = false;
          }
        })
      }
    }
  }

  onFileSelected(event) {
    this.isProcessingThumbnail = true;
    this.isProcessingUrl = true;
    if (event.target.files.length > 0) {
      if (event.target.files.length >= this.maxFiles) {
        this.isProcessingThumbnail = false;
        this.isProcessingUrl = false;
        return this.errorHandler.handleError(`Max Files Uploads-${this.maxFiles}`);
      } else {
        this.files = event.target.files;
        this.generateThumbnail(this.files[0])
        if (this.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(this.files[0]);
          if (this.files[0].type.indexOf('video') > -1) {
            reader.onload = (event) => {
              this.url = (this.sanitizer.bypassSecurityTrustUrl(`${(<FileReader>event.target).result}`) as any).changingThisBreaksApplicationSecurity;
              this.isProcessingUrl = false;
            }
          } else {
            this.files = []
            this.isProcessingUrl = false
            this.isProcessingThumbnail = false
            return this.errorHandler.handleError(`Unsuppoted File`);
          }
        }
      }
    }
  }

  onFileDrop(fileList: FileList) {
    const newFile = fileList.item(0);
    this.files = []
    this.files.push(newFile)
    if (this.files.length > 0) {
      if (this.files.length >= this.maxFiles) {
        this.isProcessingThumbnail = false;
        this.isProcessingUrl = false;
        return this.errorHandler.handleError(`Max Files Uploads-${this.maxFiles}`);
      } else {
        this.generateThumbnail(this.files[0])
        if (this.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(this.files[0]);
          if (this.files[0].type.indexOf('video') > -1) {
            reader.onload = (event) => {
              this.url = this.sanitizer.bypassSecurityTrustUrl(`${(<FileReader>event.target).result}`)
              this.isProcessingUrl = false;
            }
          } else {
            this.files = []
            this.isProcessingUrl = false
            this.isProcessingThumbnail = false
            return this.errorHandler.handleError(`Unsuppoted File`);
          }
        }
      }
    }
  }

  public generateThumbnail(videoFile: Blob) {
    const video: HTMLVideoElement = document.createElement('video');
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    if (videoFile.type) {
      video.setAttribute('type', videoFile.type);
    }
    video.preload = 'auto';
    video.src = window.URL.createObjectURL(videoFile);
    video.load();
    var i = 0;

    video.addEventListener('loadeddata', function () {
      this.currentTime = i;
    });
    let this_new = this

    video.addEventListener('seeked', function () {
      this_new.doc.meta = {
        duration: (this.duration / 60)
      }
      const context: CanvasRenderingContext2D = canvas.getContext('2d');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      if (this_new.thumbnails.length < this_new.thumnailLimit) {
        this_new.thumbnails.push({ image: canvas.toDataURL(), isSelected: false, time: i });
        i += Math.round(Number(video.duration / 4));
        if (i <= this.duration) {
          this.currentTime = i;
        }
        else {
          this_new.isProcessingThumbnail = false
          if (this_new.thumbnails.length) {
            this_new.thumbnails[0].isSelected = true
            this_new.doc.timemark = this_new.thumbnails[0].time.toString()
            this_new.selectedThumbnail = this_new.thumbnails[0].image
          }
        }
      } else {
        this_new.isProcessingThumbnail = false
      }
    });

    // return new Promise<string>((resolve, reject) => {
    //   canvas.addEventListener('error', reject);
    //   video.addEventListener('error', reject);
    //   setTimeout(function () {
    //     video.addEventListener('canplay', event => {

    //     });
    //   }, 3000)
    //   if (videoFile.type) {
    //     video.setAttribute('type', videoFile.type);
    //   }
    //   video.preload = 'auto';
    //   video.src = window.URL.createObjectURL(videoFile);
    //   video.load();
    // });
  }

  select(index) {
    let i = 0
    this.thumbnails.forEach(thumbnail => {
      if (i == index) {
        this.doc.timemark = thumbnail.time.toString()
        thumbnail.isSelected = true
      } else {
        thumbnail.isSelected = false
      }
      i++
    })
  }

  @HostListener('dragover', ['$event'])
  onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      if (files.length >= this.maxFiles) {
        this.isProcessingThumbnail = false;
        this.isProcessingUrl = false;
        return this.errorHandler.handleError(`Max Files Uploads-${this.maxFiles}`);
      } else {
        this.files = files
        this.generateThumbnail(this.files[0])
        if (this.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(this.files[0]);
          if (this.files[0].type.indexOf('video') > -1) {
            reader.onload = (event) => {
              this.url = this.sanitizer.bypassSecurityTrustUrl(`${(<FileReader>event.target).result}`)
              this.isProcessingUrl = false;
            }
          } else {
            this.files = []
            this.isProcessingUrl = false
            this.isProcessingThumbnail = false
            return this.errorHandler.handleError(`Unsuppoted File`);
          }
        }
      }
    }
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.readyForDrop = false;
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      if (files.length >= this.maxFiles) {
        this.isProcessingThumbnail = false;
        this.isProcessingUrl = false;
        return this.errorHandler.handleError(`Max Files Uploads-${this.maxFiles}`);
      } else {
        this.files = files
        this.generateThumbnail(this.files[0])
        if (this.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(this.files[0]);
          if (this.files[0].type.indexOf('video') > -1) {
            reader.onload = (event) => {
              this.url = this.sanitizer.bypassSecurityTrustUrl(`${(<FileReader>event.target).result}`)
              this.isProcessingUrl = false;
            }
          } else {
            this.files = []
            this.isProcessingUrl = false
            this.isProcessingThumbnail = false
            return this.errorHandler.handleError(`Unsuppoted File`);
          }
        }
      }
    }
  }

  @HostListener('drop', ['$event'])
  public onDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.dataTransfer.files;
    this.readyForDrop = false;
    if (files.length > 0) {
      if (files.length >= this.maxFiles) {
        this.isProcessingThumbnail = false;
        this.isProcessingUrl = false;
        return this.errorHandler.handleError(`Max Files Uploads-${this.maxFiles}`);
      } else {
        this.files = files
        this.generateThumbnail(this.files[0])
        if (this.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(this.files[0]);
          if (this.files[0].type.indexOf('video') > -1) {
            reader.onload = (event) => {
              this.url = this.sanitizer.bypassSecurityTrustUrl(`${(<FileReader>event.target).result}`)
              this.isProcessingUrl = false;
            }
          } else {
            this.files = []
            this.isProcessingUrl = false
            this.isProcessingThumbnail = false
            return this.errorHandler.handleError(`Unsuppoted File`);
          }
        }
      }
    }
  }

}
